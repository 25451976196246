table {
    width: 100%;
    margin-bottom: 20px;
    font-size: 0.933rem;
    font-weight: 300;

    thead {
        background: @border-color;
        color: @headlines;

        th {
            font-weight: 400;
            padding: 10px;
        }
    }

    td {
        padding: 10px;
        border-bottom: 1px solid @border-color;
        font-weight: 300;
    }

    &.diamonds-table {
        margin-top: 20px;
        max-width: 480px;

        td {
            border-top: 1px solid @border-color;
            border-bottom: 0;
            width: 50%;
            font-weight: 300;

            &:first-child {
                color: @headlines;
                font-weight: 400;
            }
        }
    }

    &.table-prices {
        max-width: 400px;

        tr {
            td {
                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    &.account-orders-table {
        .button {
            margin-right: 0;
            padding: 0;
            font-size: 0.867rem;
            font-weight: 300;
        }

        tr {
            td {
                &[data-title]::before {
                   font-weight: 400; 
                }
                
                > * {
                    font-weight: 300;
                }
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        &.account-orders-table {
            tr {
                td {
                    text-align: center;
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        td {
            padding: 12px 15px;
        }
    }
}
