form {
    input {
        &[type="text"],
        &[type="email"],
        &[type="password"],
        &[type="tel"] {
            height: 50px;
            padding: 12px 20px;
            -webkit-appearance: none;
            border: 1px solid @border-color;
            width: 100%;
            margin-bottom: 10px;
            color: @text;
        }
    }

    textarea {
        margin-bottom: 10px;
        padding: 12px 20px;
        -webkit-appearance: none;
        border: 1px solid @border-color;
        width: 100%;
    }

    .input-wrapper {
        position: relative;

        p {
            margin-bottom: 20px;
        }

        .required {
            position: absolute;
            font-size: 0.875rem;
            color: red;
        }
    }

    .g-recaptcha {
        margin-bottom: 10px;
    }

    .wpcf7-form-control-wrap {
        display: block;
    }

    .input-checkbox {
        margin-bottom: 10px;

        label {
            font-size: 0.875rem;

            span {
                font-weight: 300;
            }
        }
    }

    .input-file {
        margin-bottom: 10px;
    }

    span.wpcf7-not-valid-tip {
        font-size: 0.875rem;
        margin-bottom: 10px;
        display: block;
    }

    .wpcf7-list-item {
        margin: 0;
    
        label {
            display: flex;
            align-items: center;

            input {
                position: relative;
                top: -1px;
                margin-right: 5px;
            }
        }
    }

    div {
        &.wpcf7-validation-errors {
            clear: left;
            border: none;
            margin: 0;
            background: none repeat scroll 0 0 #8E2121;
            padding: 10px 20px;
            color: white;
            float: left;
            width: 100%;
        }

        &.wpcf7-mail-sent-ok {
            clear: left;
            border: none;
            margin: 0;
            background: none repeat scroll 0 0 #398f14;
            padding: 10px 20px;
            margin-top: 20px;
            color: white;
            float: left;
            width: 100%;
        }
    }

    .required {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .col-12 {
        position: relative;

        .col-6 {
            position: relative;
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .col-12 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .col-6 {
                width: calc(~"50% - 10px");
            }

            > .wpcf7-form-control-wrap {
                width: 100%;
            }

            &.flex-reverse {
                .col-6 {
                    &:first-child {
                        order: 2;
                        max-width: 400px;
                    }

                    &:last-child {
                        order: 1;
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        .col-12 {
            &.flex-reverse {
                margin-top: 20px;
            }
        }
    }
}

.woocommerce,
.woocommerce-checkout,
.woocommerce-account {
    .select2 {
        width: 100% !important;
        height: 50px;
        margin-bottom: 10px;

        .select2-selection--single {
            height: 50px;
            border-color: @border-color;
            position: relative;
            border-radius: 0;
            font-size: 0.875rem;
            .trans;
            
            .select2-selection__rendered {
                line-height: 50px;
                padding: 0 50px 0 20px;
                color: @text;
                border-radius: 0;
                font-family: 'Merriweather', sans-serif;
            }

            .select2-selection__arrow {
                position: absolute;
                top: 10px;
                right: 20px;

                b {
                    border: none;
                    left: auto;
                    right: 0;
                    margin: 0;

                    &:before,
                    &:after {
                        content: "";
                        width: 13px;
                        height: 2px;
                        display: block;
                        position: absolute;
                        background: @text;
                        top: 50%;
                        right: 0px;
                        .trans;
                    }

                    &:before {
                        transform: rotate(45deg);
                        margin-right: 9px;
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &.select2-container--open {
            .select2-selection--single {
                background: fade(#d7d8da, 25%);
                
                span {
                    color: @link;
                }

                .select2-selection__arrow {
                    b {
                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }  

    .select2-dropdown {
        border-color: @border-color;
        border-radius: 0;

        .select2-results>.select2-results__options {
            max-height: 330px;
        }

        .select2-results__option {
            padding: 12px 20px;
            border-bottom: 1px solid @border-color;
            font-size: 0.875rem;
            text-decoration: underline;
            .trans;

            &[aria-selected=true] {
                text-decoration: none;
                color: @link;
                background: transparent;
            }

            &.select2-results__option--highlighted {
                background: transparent;
                text-decoration: none;
                color: @text;
                color: @link;
            }

            &:last-child {
                border: none;
            }
        }
    } 

    // @media screen and (min-width: @screen-desktop) {
    //     .select2 {
    //         .select2-selection--single {
    //             .select2-selection__arrow {
    //                 top: 13px;
    //                 right: 15px;

    //                 b {
    //                     border-color: @text transparent transparent transparent;
    //                     border-style: solid;
    //                     border-width: 6px 4px 0 4px;
    //                 }
    //             }
    //         }

    //         &.select2-container--open {
    //             .select2-selection--single {
    //                 .select2-selection__arrow {
    //                     b {
    //                         border-color: transparent transparent @text transparent;
    //                         border-width: 0px 4px 6px  4px;
    //                     }
    //                 }
    //             }                
    //         }
    //     }
    // } 
}

.font-select {
    width: 100% !important;
    height: 50px;
    margin-bottom: 10px;   
    display: flex;
    align-items: center;
    position: relative;

    &:before {
        content: "Písmo";
        display: block;
        padding: 0;
        width: 50%;
        max-width: 50%;
        font-weight: 300;
        font-size: 0.875rem;
    }

    a {
        height: 50px;
        border: 1px solid @border-color;
        position: relative;
        border-radius: 0;
        font-size: 0.875rem;
        background: #fff;
        width: 50%;
        .trans;   
        
        span {
            line-height: 50px;
            padding: 0 50px 0 20px;
            color: @text;
            border-radius: 0;
            font-family: 'Merriweather', sans-serif;
            display: block;
        }     

        b {
            position: absolute;
            right: 20px;
            top: 10px;
            height: 26px;

            &:before,
            &:after {
                content: "";
                width: 13px;
                height: 2px;
                display: block;
                position: absolute;
                background: #555555;
                top: 50%;
                right: 0px;
                .trans;
            }

            &:before {
                transform: rotate(45deg);
                margin-right: 9px;
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    .fs-drop {
        position: absolute;
        width: 50%;
        right: 0;
        top: 49px;
        background-color: white;
        border: 1px solid @border-color;
        z-index: 1000;

        ul.fs-results {
            max-height: 330px;
            overflow-y: auto;
            margin: 0;

            li {
                padding: 12px 20px !important;
                border-bottom: 1px solid rgba(215, 216, 218, 0.5);
                line-height: 1;
                font-size: 1.125rem;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    &.font-select-active {
        a {
            background: fade(@border-color, 25%);

            span {
                color: @link;
            }

            b {
                &:before {
                    transform: rotate(-45deg);
                    margin-right: 9px;
                }

                &:after {
                    transform: rotate(45deg);
                }
            }
        }
    }
}
