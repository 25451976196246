.site-breadcrumbs {
    font-size: 0.875rem;
    
    a,
    span {
        color: @text;
        font-weight: 300;
    }

    .breadcrumb_last {
        color: @headlines;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        + span {
            position: relative;
            width: 10px;
            margin: 0 15px 0 5px;
            height: 18px;

            &:before,
            &:after {
                content: "";
                width: 8px;
                height: 1px;
                display: block;
                position: absolute;
                background: @text;
                top: 50%;
                left: 50%;
            }

            &:before {
                transform: rotate(45deg);
                margin-top: -3px;
            }

            &:after {
                transform: rotate(-45deg);
                margin-top: 2px;
            }
        }
    }
}
