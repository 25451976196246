.rstRad(@radius: 0px) { -webkit-border-radius: @radius; -moz-border-radius: @radius; border-radius: @radius; }
.radius(@radius: @border-radius-base) { .rstRad(@radius); }

.shadow() {
    box-shadow: 0px 8px 68px 0px rgba(0, 0, 0, 0.09);
}

.clearfix() {
    &:before,
    &:after {
      content: " "; // 1
      display: table; // 2
    }
    &:after {
      clear: both;
    }
}

.trans() {
    -webkit-transition: 0.3s all ease-out;
    -moz-transition: 0.3s all ease-out;
    -o-transition: 0.3s all ease-out;
    -ms-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
}
