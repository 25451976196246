.site-header {
    height: 60px;
    background: white;
    position: relative;
    z-index: 99;

    &:before {
        display: block;
        content: "";
        width: 100%;
        background-image: -moz-linear-gradient( 0deg, rgb(195,173,57) 0%, rgb(160,113,5) 100%);
        background-image: -webkit-linear-gradient( 0deg, rgb(195,173,57) 0%, rgb(160,113,5) 100%);
        background-image: -ms-linear-gradient( 0deg, rgb(195,173,57) 0%, rgb(160,113,5) 100%);
        height: 5px;
    }

    .woocommerce-checkout &,
    .woocommerce-cart & {
        border-bottom: 1px solid @border-color;
    }

    .header-info {
        display: none;
    }

    > .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .site-branding {
        position: absolute;

        figure {
            figcaption {
                display: none;
            }
        }
    }

    .site-cart {
        margin-right: 0;
        margin-left: auto;
        margin-top: -7px;

        p.woocommerce-mini-cart__buttons {
            padding: 0;
        }

        .product_list_widget {
            li {
                align-items: flex-start;
                flex-wrap: wrap;
                padding: 20px 0;

                &:last-child {
                    border: none;
                }

                figure {
                    border: 1px solid @border-color;
                    margin-right: 20px;

                    img {
                        margin: 0;
                        max-width: 100px;
                    }
                }

                .info {
                    width: calc(~"100% - 122px");

                    a {
                        text-decoration: none;

                        strong {
                            font-size: 0.938rem;
                        }
                    }

                    .price {
                        margin-bottom: 5px;

                        span {
                            font-size: 1.125rem;
                            margin-left: 0;
                            font-weight: 400;
                        }
                    }

                    .control-remove {
                        a {
                            color: @text;
                            font-size: 0.813rem;
                            font-weight: 300;
                            position: static;

                            svg {
                                margin-right: 5px;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        li {
            display: flex;
            align-items: center;
            padding: 0;

            > span,
            > a {
                font-size: 0.938rem;
                font-weight: 300;
                font-family: 'Merriweather', sans-serif;
                margin-left: 10px;
                position: relative;
                top: 3px;
                color: @text;
                .trans;
            }

            > a {
                top: -1px;
                padding: 0;
                text-decoration: none;     
            }

            svg {
                .trans;
            }

            .widget_shopping_cart {
                position: absolute;
                right: 0px;
                left: auto;
                top: 60px;
                z-index: 9;
                background: #fff;
                border-top: 1px solid @border-color;
                width: 100%;
                padding: 0 20px 20px;
                max-width: 420px;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
            
                .woocommerce-mini-cart__empty-message {
                    margin: 20px 0 0;
                }
            }
        }

        .site-header-cart {
            cursor: pointer;
            height: 60px;
            display: flex;
            position: static;

            .widget_shopping_cart {
                display: none;
            }

            &:hover {
                li {
                    span,
                    > a {
                        color: @link;
                    }

                    svg {
                        fill: @link;
                    }
                }

                .widget_shopping_cart {
                    display: block;
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        height: auto;

        .header-info {
            display: block;
            border-bottom: 1px solid @border-color;

            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;

                &:before,
                &:after {
                    display: none;
                }

                .header-contacts {
                    display: flex;
                    align-items: center;
                    font-size: 0.813rem;
                    color: @text;

                    span {
                        font-weight: 300;
                        display: flex;
                        align-items: center;
                        font-size: 0.8rem;

                        &:after {
                            display: inline-block;
                            content: "";
                            height: 10px;
                            width: 1px;;
                            background: @border-color;
                            margin: 0 10px;
                        }

                        &:last-of-type {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                .header-links {
                    ul {
                        display: flex;
                        align-items: center;

                        li {
                            a {
                                color: @text;
                                font-weight: 300;
                                text-decoration: none;
                                font-size: 0.8rem;

                                &:hover {
                                    text-decoration: underline;
                                    color: @link;
                                }
                            }

                            &:after {
                                display: inline-block;
                                content: "";
                                height: 10px;
                                width: 1px;;
                                background: @border-color;
                                margin: 0 10px;
                            }

                            &:last-of-type {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        > .container {
            justify-content: space-between;

            &:before,
            &:after {
                display: none;
            }
        }

        .nav-main {
            margin-right: 0;
            margin-left: auto;
        }

        .site-cart {
            margin-top: 0;
            margin-left: 40px;

            .site-header-cart {
                height: 97px;
                position: relative;
                padding-left: 40px;
            }

            li {
                > svg {
                    top: -4px;
                    position: relative;
                }

                span {
                    font-size: 1rem;
                    top: -1px;
                }

                .widget_shopping_cart {
                    top: auto;
                    bottom: 0;
                    transform: translateY(100%);
                    width: 400px;
                    right: 0;
                    left: auto;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .site-branding {
            position: relative;
            padding: 10px 0;

            figure {
                margin-left: -10px;

                picture {
                    display: flex;
                }
            }
        }
    }
}