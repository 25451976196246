@import url('https://fonts.googleapis.com/css?family=Italianno|Merriweather:300,400&subset=latin-ext');

html {
    font-family: 'Merriweather', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5625;
    color: @text;
    font-size: 15px;
    font-weight: 300;
}

*:focus,
*:active {
    outline: none;
}

a {
    color: @link;
    .trans;

    &.image-center {
        display: flex;
        justify-content: center;
    }

    .link-underline {
        display: table;
        .trans;

        &:after {
            width: 150%;
            content: "";
            height: 1px;
            display: block;
            background: fade(#555555, 50%);
            margin-top: 2px;
            .trans;
        }
    }

    &:hover {
        text-decoration: none;

        .link-underline {
            color: @link;

            &:after {
                width: 100%;
                background-color: @link;
            }
        }
    }
}

.gdpr-floating-button-custom-position {
    display: none !important;

    .home & {
        display: block !important;
    }
}

.grecaptcha-badge {
    display: none !important;
}

article {
    ul {
        margin-bottom: 20px;

        li {
            font-weight: 300;
            line-height: 2.5;

            &:before {
                content: "";
                display: inline-block;
                background-color: #777777;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                position: relative;
                margin-right: 10px;
                top: -2px;
            }
        }
    }
}

img {
    height: auto;
}

address {
    font-style: normal;
}

strong {
    font-weight: 400;
    color: @headlines;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
    display: block;
    color: @headlines;
    font-weight: 400;
}

h1, .h1 {
    margin: 40px 0;
    text-align: center;

    @media screen and (min-width: @screen-desktop) {
        text-align: left;
    }
}

.h2, h2 {
    font-size: 1.200rem;
    margin-top: 40px;
    margin-bottom: 15px;

    article & {
        text-transform: none;
    }

    @media screen and (min-width: @screen-tablet) {
        font-size: 1.5rem;
        margin-top: 60px;
    }

    @media screen and (min-width: @screen-desktop) {
        font-size: 1.563rem;
    }
}

.h3, h3 {
    font-size: 1.125rem;
    margin-top: 30px;
    margin-bottom: 5px;

    article & {
        text-transform: none;
    }

    @media screen and (min-width: @screen-desktop) {
        font-size: 1.250rem;
    }
}

.h4, h4 {
    font-size: 1rem;
    margin-top: 30px;
    margin-bottom: 5px;
}

p {
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 2.1;
    font-size: 0.933rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    a {
        &.link-center {
            display: block;
            text-align: center;

            span {
                font-weight: 300;
                font-size: 0.933rem;
                display: block;
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        a {
            &.link-center {
                display: table;
            }
        }
    }
}

label,
span {
    font-weight: 300;

    &.price {
        font-weight: 400;
    }
}

address {
    font-weight: 300;
    line-height: 2.1;    
}

small {
    font-weight: 300;
}
