.container {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    .clearfix;

    > h2 {
        margin-top: 0;
        margin-bottom: 40px;
    }

    @media (min-width: @screen-desktop) {
        max-width: 1200px;
    }
}

.flex-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flex-box {
        display: flex;
    }

    > div {
        width: 100%;
    }
}

.jQuery-plainOverlay-progress {
    width: 50% !important;
    height: 50% !important;
    left: 25%;
    top: 25%;
    position: relative;
    border-color: @main-color !important;
}
