.woocommerce-order,
.woocommerce-order-pay .woocommerce,
.woocommerce-view-order .woocommerce {
    .woocommerce-thankyou-order-details,
    .wc-bacs-bank-details {
        margin: 20px 0 0;
        padding: 10px 20px;
        border: 1px solid @border-color;

        li {
            padding: 10px 0;
            border-bottom: 1px solid @border-color;
            font-size: 0.867rem;

            strong {
                font-size: 1rem;
            }

            span {
                font-weight: 400;
                font-size: 1rem;
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &:before {
                display: none;
            }
        }

        + p {
            display: none;
        }
    }

    h2 {
        margin-top: 35px;
    }

    table {
        background: fade(@border-color, 25%);
        margin-bottom: 0px;

        tfoot {
            th {
                border-bottom: none;
            }
        }

        th {
            &:first-child {
                text-align: left;
            }
        }

        .product-name {
            font-size: 1rem;
        }

        tfoot {
            th {
                color: @headlines;
                vertical-align: middle;
                text-align: right;
                border: none;
                font-weight: 300;
                padding: 10px;
            }

            td {
                text-align: right;
                border-bottom: none;
                font-weight: 400;
                color: @headlines;

                span {
                    font-weight: 400;
                    color: @headlines;
                }

                small {
                    display: none;
                }
            }
        }

        .wc-item-meta {
            margin-top: 10px;
            margin-bottom: 0;

            li {
                p {
                    font-weight: 400;
                    color: @headlines;
                }

                &:before {
                    display: none;
                }

                strong {
                    font-size: 0.933rem;
                    color: @text;
                    font-weight: 300;
                    margin-right: 5px;
                }
            }
        }
    }

    .pager.wizard {
        display: none;
    }

    #order_review {
        table {
            margin-bottom: 40px;
        }

        ul {
            margin-bottom: 40px !important;
        }

        #payment {
            .form-row {
                padding: 0;

                .btn {
                    font-size: 1rem;
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            .payment_methods {
                margin-top: 20px;

                li {
                    border-radius: 4px;
                    border: 1px solid fade(#b2b2b2, 50%) !important;
                    margin: 0 0 10px 0 !important;
                    position: relative;
                    padding: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:before {
                        display: none;
                    }

                    input {
                        position: absolute;
                        top: 16px;
                        left: 20px;
                        margin-right: 0;

                        &:checked + label {
                            background: #e5e5e5;
                        }
                    }

                    label {
                        display: block;
                        padding: 8px 20px 8px 50px !important;

                        .woocommerce-Price-amount {
                            font-weight: 700;

                            span {
                                font-weight: 700;
                            }
                        }

                        &:after {
                            content: "0 Kč";
                            display: inline-block;
                            font-weight: 700;
                        }
                    }

                    .payment_box {
                        padding: 10px 20px;
                    }
                }
            }
        }
    }

    .woocommerce-columns--addresses {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        > div {
            width: 100%;
            line-height: 2;

            h2 {
                padding-bottom: 5px;
                margin-top: 30px;
                border-bottom: 1px solid @border-color;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 0;
            }   
        }
    }

    .woocommerce-customer-details .woocommerce-column {
        border: 1px solid @border-color;
        padding: 20px;
        margin-top: 20px;
        width: 100%;

        h2 {
            margin: 0 0 10px;
            padding: 0;
            border: none;
            font-size: 1.2rem;
        }

        address {
            font-size: 0.867rem;
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .woocommerce-customer-details .col2-set {
            justify-content: space-between;

            .woocommerce-column {
                width: calc(~"50% - 10px");
                margin: 0;
            }
        }

        table {
            margin-bottom: 20px;

            tfoot {
                th {
                    border-bottom: none;
                    padding-left: 40%;
                }
            }
        }

        .woocommerce-customer-details .woocommerce-column {
            width: 50%;
        }
    }
}

.woocommerce-order-received {
    .woocommerce {
        max-width: 840px;
        margin-top: 40px;
    }
}
