.archive {
    .site-main {
        > header {
            background-image: -moz-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
            background-image: -webkit-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
            background-image: -ms-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
            padding: 40px 0;

            h1 {
                text-align: left;
                margin: 0 0 10px;
                font-family: "Italianno";
                text-transform: none;
                font-size: 3.5rem;
                color: #111;
                line-height: 1;
            }

            + section {
                padding-top: 40px;
            }
        }

        .category-desc-wrapper {
            border-bottom: 1px solid @border-color;
            padding-bottom: 15px;

            &.toggled {
                .term-description {
                    max-height: 350px;
                    margin-bottom: 15px;

                    &:after {
                        opacity: 0;
                    }
                }
            }
        }

        .term-description {
            margin-top: 20px;
            position: relative;
            max-height: 100px;
            opacity: 1;
            overflow: hidden;
            .trans;

            &:after {
                content: "";
                .trans;
                display: block;
                height: 60px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
            }

            + button {
                padding: 0;
                border: none;
                background: transparent;
                font-size: 0.938rem;
                font-weight: 300;
                color: @text;
                font-family: "Merriweather";
                text-decoration: underline;
                .trans;

                &:hover {
                    text-decoration: none;
                    color: @link;
                }
            }
        }

        .filters-wrapper {
            border: 1px solid @border-color;
            margin-bottom: 10px;
            margin-top: 20px;

            header {
                .filters-button {
                    margin: 0;
                    padding: 16px 20px;
                    display: block;
                    text-decoration: none;
                    background: transparent;
                    border: none;
                    width: 100%;
                    text-align: left;
                    font-size: 0.938rem;
                    font-weight: 400;
                    position: relative;
                    color: @text;
                    .trans;
                    font-family: 'Merriweather', sans-serif;

                    &:before,
                    &:after {
                        content: "";
                        width: 13px;
                        height: 2px;
                        display: block;
                        position: absolute;
                        background: @text;
                        top: 50%;
                        right: 20px;
                        .trans;
                    }

                    &:before {
                        transform: rotate(45deg);
                        margin-right: 9px;
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .filters-list {
                max-height: 0;
                overflow: hidden;
                .trans;

                > div {
                    padding: 20px;
                    position: relative;

                    .woof_redraw_zone {
                        > div {
                            &:nth-last-of-type(2) {
                                padding-bottom: 0;
                                margin-bottom: 0;

                                &:after {
                                    display: none;
                                }
                            }                            
                        }
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        display: block;
                        left: 20px;
                        width: calc(~"100% - 40px");
                        height: 1px;
                        background: @border-color;
                        bottom: 0;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }

                    .widget-woof {
                        .woof_price_filter {
                            .irs-grid,
                            .irs-min,
                            .irs-max,
                            .irs-from,
                            .irs-to {
                                display: none;
                            }

                            .irs-single {
                                visibility: visible !important;
                                position: static;
                                background: transparent;
                                font-size: 0.938rem;
                                font-weight: 300;
                                color: @text;
                                padding: 0;
                                transform: translateY(-10px);
                            }

                            .irs-line {
                                top: 35px;
                                background: #f5f5f6;
                                border-radius: 4px;

                                > span {
                                    background: transparent;
                                }
                            }

                            .irs-bar {
                                top: 35px;
                                background: #bbbbbb;
                            }

                            .irs-slider {
                                top: 27px;
                                background: @main-color;
                                border-radius: 100%;
                            }
                        }
                    }

                    .woof_list_checkbox {
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            padding: 0 !important;
                            margin: 0 !important;
                            display: flex;
                            align-items: center;
                            height: 30px;

                            &:last-child {
                                margin: 0 !important;
                            }

                            input {
                                position: relative;
                                top: -1px;

                                &:checked + label {
                                    font-weight: 400;
                                    color: @headlines;
                                }
                            }

                            label {
                                padding: 0;
                                margin-right: 10px;
                                font-size: 0.875rem;
                                margin-left: 5px;
                                font-weight: 300;
                                color: @text;
                            }

                            input[disabled] {
                                display: none;
                            }

                            input[disabled] + label {
                                pointer-events: none;
                                display: none !important;
                            }
                        }
                    }

                    .woof_container_stock {
                        .woof_container_inner {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .woof_container {
                        position: relative;
                        margin-bottom: 20px;
                        padding-bottom: 20px;

                        &.woof_price_filter {
                            padding-bottom: 10px;
                        }

                        &:after {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            background: @border-color;
                            bottom: 0;
                        }

                        &.woof_container_stock {
                            margin-bottom: 0;
                            padding-bottom: 0;

                            &:after {
                                display: none;
                            }
                        }
                    }

                    .woof_submit_search_form_container {
                        margin: 0;

                        button {
                            width: 100%;
                            height: 40px;
                            background: @main-color;
                            color: white;
                            border: none;
                            font-size: 1rem;
                            border-radius: 4px;
                            margin-top: 20px;
                            max-width: 240px;
                            margin-right: 10px;
                            .trans;

                            &:hover {
                                background: darken(@main-color, 15%)
                            }
                        }
                    }

                    .widgettitle,
                    h4 {
                        margin-bottom: 10px;
                        display: block;
                        margin-top: 0;
                        font-weight: 400;
                        font-size: 0.875rem;
                    }

                    .price_slider {
                        position: relative;
                        height: 10px;
                        background: #e5e5e5;
                        margin: 15px 10px;

                        &:before {
                            content: "";
                            display: block;
                            height: 10px;
                            position: absolute;
                            width: calc(~"100% + 20px");
                            left: -10px;
                            background: #e5e5e5;
                        }

                        .ui-slider-range {
                            background: #b2b2b2;
                            height: 10px;
                            display: block;
                            position: absolute;
                        }

                        .ui-slider-handle {
                            background: @main-color;
                            display: block;
                            height: 20px;
                            width: 20px;
                            border-radius: 4px;
                            position: absolute;
                            margin-left: -10px;
                            margin-top: -5px;
                        }
                    }

                    .woocommerce-widget-layered-nav-list {
                        li {
                            a {
                                color: @text;
                                text-decoration: none;

                                &:before {
                                    content: "";
                                    display: inline-block;
                                    height: 20px;
                                    width: 20px;
                                    border-radius: 4px;
                                    border: 1px solid fade(#b2b2b2, 50%);
                                    position: relative;
                                    top: 3px;
                                    margin-right: 5px;
                                } 

                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                }                           
                            }

                            &.chosen {
                                a {
                                    &:before {
                                        background: #b2b2b2;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.toggled {
                header {
                    .filters-button {
                        background: #f5f5f6;
                        color: @link;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }

                .filters-list {
                    max-height: 1000px;
                }
            }
        }

        .products-order {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .order-wrapper {
                margin-bottom: 10px;
                width: 100%;

                .select2 {
                    .select2-selection--single {
                        .select2-selection__rendered {
                            font-size: 0.938rem;
                            font-family: 'Merriweather', sans-serif;
                        }
                    }

                    &.select2-container--open {
                        .select2-selection--single {
                            .select2-selection__arrow {
                                b {
                                    &:before {
                                        transform: rotate(-45deg);
                                    }

                                    &:after {
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .widget_product_categories {
            margin: 10px 0 -10px;

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;
                    display: flex;
                    align-items: center;

                    &:before {
                        content: "";
                        display: inline-block;
                        background-color: #777777;
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        position: relative;
                        margin-right: 10px;
                        top: -2px;
                    }

                    a {
                        color: @headlines;
                        font-size: 0.938rem;
                        font-weight: 300;
                        padding: 10px 0;
                        display: block;

                        &:hover {
                            color: @link;
                        }
                    }
                }
            }
        }

        .category-page {
            li {
                order: 2;

                &.featured {
                    order: 1;
                }
                
                a {
                    text-decoration: none;
                    text-align: center;
                    padding-bottom: 20px;
                    display: block;
                    border-bottom: 1px solid @border-color;

                    h2 {
                        margin-top: 0;
                        font-size: 1rem;
                        margin-bottom: 10px;
                        .trans;
                    }

                    .price {
                        display: table;
                        margin: 0 auto;

                        del {
                            color: @text;
                            opacity: 1;
                            font-size: 0.875rem;
                        }

                        ins {
                            text-decoration: none;
                        }

                        span {
                            font-weight: 400;
                        }

                        .woocommerce-price-suffix {
                            display: none;
                        }
                    }

                    &:hover {
                        h2 {
                            color: @link;
                        }
                    }
                }
            }
        }

        .woocommerce-pagination,
        .lmp_load_more_button {
            padding: 40px 0;
            float: none;
            margin: 0 -20px;

            .page-numbers {
                display: flex;
                justify-content: center;

                li {
                    padding: 0 5px;

                    a,
                    span {
                        min-width: 20px;
                        display: block;
                        text-align: center;
                    }

                    span {
                        color: @link;
                    }

                    a {
                        color: @text;
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        .site-main {
            .category-page {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;

                li {
                    width: calc(~"50% - 10px");
                    margin: 0 5px;
                }
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .site-main {
            .category-page {

            }

            .category-desc-wrapper {
                .term-description {
                    max-height: 60px;
                }
            }

            .widget_product_categories {
                margin: 20px 0;

                ul {
                    li {
                        width: percentage(1/3);
                    }
                }
            }

            .products-order {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .results-number {
                    order: 1;
                }
                
                .order-wrapper {
                    width: 50%;
                    max-width: 400px;
                    order: 2;

                    .woocommerce-ordering {
                        float: none;
                        margin: 0;
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        .site-main {
            .category-page {   
                margin: 0 -10px; 

                li {
                    width: calc(~"33.333% - 20px");
                    margin: 0 10px;

                    a {
                        &:hover {
                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                            z-index: 9;
                            position: relative;
                        }
                    }
                }
            }

            .widget_product_categories {
                ul {
                    li {
                        width: 25%;
                    }
                }
            }
        }
    }


    @media screen and (min-width: @screen-lg-desktop) {
        .site-main {
            .category-page {   
                margin: 0 -10px; 

                li {
                    width: calc(~"25% - 20px");
                    margin: 0 10px;
                }
            }
        }
    }
}
