.woocommerce-cart-form {
    .shop_table {
        margin-bottom: 0;

        td, th {
            padding: 0;
            border: none;
            text-align: left;
            clear: none;
            display: block;
        }

        tr {
            position: relative;
            display: block;
        }

        td {
            &.product-thumbnail {
                img {
                    max-width: none;
                    width: 100%;
                }
            }

            &.product-name {
                a {
                    color: @headlines;
                    font-size: 1.125rem;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        color: @link;
                    }

                    &.tm-cart-edit-options {
                        font-weight: 300;
                        display: table;
                        margin: 10px 0;
                        font-size: 0.875rem;
                        text-decoration: underline;
                        color: @link;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .variation {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: 10px 0 0;

                    dt,
                    dd {
                        width: 50%;
                        margin-bottom: 0;
                        font-size: 0.938rem;
                        color: @text;
                        font-weight: 300;
                        line-height: 2.1;
                        border-bottom: 1px solid @border-color;
                        padding: 5px 0;
                    }

                    dd {
                        p {
                            font-weight: 400;
                        }
                    }

                    > div {
                        display: flex;
                        width: 100%;

                        > span,
                        strong {
                            width: 50%;
                            display: block;
                            margin-bottom: 0;
                            font-size: 0.938rem;
                            color: @text;
                            font-weight: 300;
                            line-height: 2.1;
                            border-bottom: 1px solid @border-color;
                            padding: 5px 0;
                        }

                        span {
                            font-weight: 400;
                        }
                    }
                }

                div.variation {
                    margin-top: 0;
                }
            }

            &.product-remove {
                margin-top: 5px;
                border: none;

                .remove {
                    position: static;
                    font-size: 0.875rem;
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    margin-right: 0;
                    justify-content: flex-end;
                    color: @text;
                    padding: 5px;
                    width: 90px;

                    span {
                        font-weight: 300;
                    }

                    svg {
                        margin-right: 5px;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }

        .actions {
            border: none;
            margin-top: 20px;

            a {
                display: flex;
                width: 165px;
                font-weight: 300;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        .shop_table {
            td {
                &.product-thumbnail {
                    width: 300px;
                    margin: 0 auto;

                    img {
                        width: 300px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .shop_table {
            border: none;
            position: relative;

            thead {
                display: none;
            }

            tr {
                display: table-row;

                &.cart_item {
                    padding-top: 0;

                    > td {
                        border-bottom: 1px solid fade(#b2b2b2, 50%);
                    }
                }
            }

            td, 
            th {
                display: table-cell;
                float: none;
                margin: 0;
                padding: 10px !important;
            }

            td {
                vertical-align: middle;
                min-height: 40px;

                &.product-remove {
                    vertical-align: bottom;
                    
                    .remove {
                        margin-top: 0;
                        text-align: center;
                        display: block;
                    }
                }

                &.product-thumbnail {
                    padding: 20px 0;
                    width: auto;
                    float: none;
                    margin: 0;

                    a {
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                }

                &.product-name {
                    margin: 0;
                    width: auto;

                    .variation {
                        div {
                            &:last-of-type {
                                span,
                                strong {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }

                &.actions {
                    padding-top: 20px !important;
                }
            }
        }
    }
}

.cart-collaterals {
    width: calc(~"100% + 40px");
    margin: 20px -20px 0;
    padding: 20px;
    background: fade(@border-color, 25%);

    .shop_table {
        margin-bottom: 0;

        td,
        th {
            padding: 0;
            border: none;
            font-weight: 300;
            color: @headlines; 
            vertical-align: baseline;
        }

        .cart-subtotal {
            td {
                &:before {
                    font-weight: 300;
                }

                span {
                    font-weight: 600;
                    color: @headlines;    
                    font-size: 1.125rem;               
                }

                small {
                    display: block;
                    margin-top: 5px;
                    font-weight: 300;
                    font-size: 0.933rem;

                    span {
                        font-weight: 300;
                        font-size: 0.933rem;
                    }
                }
            }
        }
    }

    .wc-proceed-to-checkout {
        margin-bottom: 0;

        .btn {
            width: 100%;
            height: 50px;
            margin-top: 20px;
            margin-bottom: 0;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-left: 10px;
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        margin: 20px 0 0 auto;
        width: 440px;

        .cart_totals {
            max-width: 400px;
            width: 100%;

            th {
                text-align: left;
            }
        }
    }
}
