.nav-main {
    .menu-toggle {
        padding: 0;
        border: none;
        background: transparent;
        z-index: 999;
        position: absolute;
        display: flex;
        align-items: center;
        left: 20px;
        top: 21px;
        .trans;

        div {
            width: 25px;

            span {
                display: block;
                width: 25px;
                height: 2px;
                background: #000;
                margin: 5px 0;
                .trans;
            }
        }

        strong {
            font-size: 0.938rem;
            font-weight: 300;
            font-family: 'Merriweather', sans-serif;
            margin-left: 10px;
            color: @text;
        }
    }

    .nav-wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: #000;
        height: 100vh;
        z-index: 99;
        transform: translateX(-100%);
        .trans;
        overflow-y: scroll;
        overflow-x: hidden;

        @-moz-document url-prefix() {
            overflow-y: hidden;
        }

        &::-webkit-scrollbar {
            width: 6px;
            position: absolute;
            right: 0;
            z-index: 99;
        }
         
        &::-webkit-scrollbar-track {
            background: transparent;
        }
         
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            .trans;
            border-radius: 4px;
        }

        #primary-menu {
            padding: 80px 40px 0;

            li {
                margin-bottom: 20px;

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 1.125rem;
                    text-transform: uppercase;
                    line-height: 2;
                    width: 100%;
                    display: block;
                }

                .dropdown-toggle {
                    display: none;
                }

                .sub-menu {
                    padding-left: 20px;
                    margin: 10px 0 20px;

                    li {
                        margin-bottom: 10px;

                        a {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .nav-contact {
            padding: 20px;
            margin: 0 20px;
            border-top: 1px solid #3e3e3e;

            span {
                display: block;
                line-height: 2;
                font-size: 1.125rem;
                color: fade(#fff, 50%);
                font-weight: 300;
            }
        }
    }

    &.toggled {
        .menu-toggle {
            left: calc(~"100% - 40px");

            div {
                span {
                    background: #fff;

                    &:nth-child(1) {
                        transform: translateY(4px) rotate(45deg);
                    }

                    &:nth-child(2) {
                        display: none;
                    }

                    &:nth-child(3) {
                        transform: translateY(-3px) rotate(-45deg);
                    }
                }
            }

            strong {
                display: none;
            }
        }

        .nav-wrapper {
            transform: none;
        }
    }

    @media screen and (min-width: @screen-desktop) {
        .menu-toggle {
            display: none;
        }

        .nav-wrapper {
            display: block;
            position: relative;
            transform: none;
            height: auto;
            background: transparent;
            width: auto;
            overflow: visible;

            #primary-menu {
                padding: 0;
                display: flex;

                > li > a {
                    display: flex;
                    height: 97px;
                }

                li {
                    margin: 0 20px;
                    display: inline-block;

                    a {
                        font-size: 1rem;
                        color: @text;
                        font-weight: 300;
                        text-transform: none;
                        position: relative;
                        align-items: center;
                        flex-wrap: wrap;

                        &:after {
                            content: "";
                            display: block;
                            width: 0;
                            height: 1px;
                            background: @link;
                            left: 0;
                            position: absolute;
                            margin-top: 20px;
                            .trans;
                        }

                        &:hover {
                            color: @link;

                            &:after {
                                width: 100%;
                            }
                        }
                    }

                    &.menu-item-has-children:not(.item-rings) {
                        position: relative;

                        > .sub-menu {
                            position: absolute;
                            display: none;
                            width: 300px;
                            top: 97px;
                            padding: 20px;
                            background: #fff;
                            border-top: 1px solid rgba(215, 216, 218, 0.5);
                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                            left: -20px;
                            margin-top: 0;

                            li {
                                line-height: 2;
                                margin: 0 0 10px;
                                display: block;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                a {
                                    font-size: 0.933rem;
                                    display: table;
                                    width: auto;

                                    &:after {
                                        margin-top: auto;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .sub-menu {
                                display: block;
                            }
                        }
                    }

                    &.item-rings {
                        > .sub-menu {
                            width: 520px;
                            position: absolute;
                            background: #fff;
                            margin: 0;
                            padding: 20px;
                            border-top: 1px solid rgba(215, 216, 218, 0.5);
                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                            display: none;

                            li {
                                margin: 0;
                                width: 50%;

                                a {
                                    font-weight: 400;
                                }

                                .sub-menu {
                                    position: static;
                                    display: block;
                                    width: auto;
                                    padding: 0;
                                    border-top: 0;
                                    margin-top: 10px;
                                    box-shadow: none;

                                    li {
                                        width: 100%;

                                        a {
                                            font-weight: 300;
                                        }
                                    }
                                }
                            }

                            > li {
                                a {
                                    display: table;
                                    width: auto;

                                    &:after {
                                        margin-top: 0;
                                    }
                                }
                            }

                            ul {
                                margin: 0;
                                padding: 0;
                                display: block;
                            }
                        }

                        &:hover {
                            > .sub-menu {
                                display: flex;
                            }
                        }
                    }
                }
            }

            .nav-contact {
                display: none;
            }
        }
    }
}