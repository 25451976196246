.page .page-contact {
    .site-main {
        article {
            .clearfix;

            > header {
                + section {
                    padding-top: 0;
                }
            }
        }
    }

    section {
        font-size: 0.933rem;

        p {
            strong {
                font-weight: 400;
                color: @headlines;
            }
        }
    }

    .contact-basic {
        padding-bottom: 40px;
    }

    .contact-map {
        display: flex;
        margin: 0 -20px;
    }

    .contact-representatives {
        padding-bottom: 40px;
    }

    .contact-form {
        border-top: 1px solid @border-color;
    }

    @media screen and (min-width: @screen-desktop) {
        .site-main {
            padding-bottom: 60px;
        }

        section.container {
            column-count: 2;
            flex: 2;
            column-gap: 40px;

            &:before,
            &:after {
                display: none;
            }

            > section {
                break-inside: avoid;

                &.contact-basic {
                    order: 1;
                    padding-top: 40px;

                    div {
                        &:first-child {
                            h2 {
                                margin-top: 0;
                            }
                        }
                    }                    
                }

                &.contact-representatives {
                    order: 2;
                    padding-top: 40px;

                    div {
                        &:first-child {
                            h2 {
                                margin-top: 0;
                            }
                        }
                    }
                }

                &.contact-map {
                    margin: 0;
                    order: 3;
                }

                &.contact-form {
                    order: 4;
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        section.container {
            column-gap: 80px;
        }
    }
}