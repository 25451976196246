.woocommerce-cart ul.nav,
#checkout-wizard ul.nav {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    border: 1px solid @border-color;

    .woocommerce-order-received & {
        display: none;
    }

    li {
        width: percentage(1/5);

        &:before {
            display: none;
        }

        a {
            display: flex;
            width: 100%;
            justify-content: center;
            height: 40px;
            align-items: center;
            flex-wrap: wrap;
            text-decoration: none;
            position: relative;
            color: @text;
            pointer-events: none;

            &:after {
                width: 1px;
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                background: fade(@border-color, 25%);
                top: 0;
                right: 0;
            }

            .number {
                display: flex;
                width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }

            .title {
                display: none;
                font-size: 0.875rem;
                width: 100%;
            }

            &.show {
                background: fade(@border-color, 25%);
                text-decoration: underline;
                color: @link;
                pointer-events: auto;

                &.active {
                    background: @link;
                    text-decoration: none;

                    .number {    
                        color: #fff;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            } 
        }

        &:last-child {
            a {
                &:after {
                    display: none;
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        margin: 40px 0 60px;

        li {
            a {
                height: 50px;
                align-items: center;

                .number {
                    margin-right: 10px;
                    border: 1px solid @border-color;
                    font-size: 0.875rem;
                    color: @text;
                }

                .title {
                    display: block;
                    width: auto;
                    line-height: 1;
                }

                &.show {
                    background: transparent;
                    text-decoration: none;
                    color: @link;
                    pointer-events: auto;

                    .number {
                        background: #fff;
                        color: @headlines;
                    }

                    .title {
                        text-decoration: underline;
                    }

                    &.active {
                        background: transparent;
                        text-decoration: none;

                        .number {    
                            color: #fff;
                            background: @link;
                            border-color: @link;
                        }

                        .title {
                            text-decoration: none;
                        }
                    }

                    &:hover {
                        background: fade(@border-color, 25%);

                        .title {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

.pager.wizard {
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0;
    align-items: center;

    .woocommerce-order-received & {
        display: none;
    }

    li {
        &:before {
            display: none;
        }

        button {
            &.btn-primary {
                width: 180px;
                display: flex;
                align-items: center;
                height: 50px;
                justify-content: center;
                
                svg {
                    margin-left: 5px;
                }

                span {
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }

        a {
            display: none;
        }

        &.previous {
            a,
            button {
                padding: 0;
                background: transparent;
                border: none;
                font-size: 0.933rem;
                color: @link;
                font-family: 'Merriweather', sans-serif;
                font-weight: 400;
                text-decoration: underline;
                align-items: center;

                &:hover {
                    text-decoration: none;
                }

                svg {
                    margin-right: 5px;
                }
            }

            button {
                display: flex;
            }
        }
    }

    &.first {
        .previous {
            button {
                display: none;
            }

            a {
                display: flex;
            }
        }
    }
}

#checkout-wizard {
    form {
        > .tab-pane {
            display: none;

            &.active {
                display: block;
            }
        }
    } 

    .place-order {
        display: none;
    }

    .tab-content {
        max-width: 840px;
        margin: 0 auto;  
        position: relative;      
    }
}

.woocommerce-checkout {
    h3 {
        padding-bottom: 10px;
        border-bottom: 1px solid @border-color;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .inputs-error {
        margin-top: 10px;
        color: red;
        display: none;

        &.active {
            display: block;
        }
    }

    .woocommerce-NoticeGroup-checkout {
        display: none;
    }

    .woocommerce-additional-fields {
        h3 {
            margin-top: 25px;
        }
    }

    .woocommerce-error {
        padding: 10px 20px;
        margin: 0;
        border: none;
        margin-top: 40px;
        float: left;
        width: 100%;

        li {
            padding: 0;

            &:before {
                display: none;
            }
        }
    }

    .woocommerce-shipping-fields {
        .shipping_address {
            padding-top: 10px;
        }

        > span {
            margin-bottom: 10px;
            display: block;
        }
    }

    .woocommerce-checkout-review-order-table {
        border-bottom: 1px solid @border-color;
        margin: 0 -20px 20px;
        width: calc(~"100% + 40px");
        
        th,
        td {
            padding: 10px 20px;
        }

        thead {
            th {
                &:first-child {
                    text-align: left;
                }                   
            }
        }

        .product-name {
            font-weight: 400;
            font-size: 1.067rem;

            .tm-cart-edit-options {
                display: block;
                font-size: 0.867rem;
                font-weight: 300;
                margin: 5px 0 0;
            }

            .variation {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 10px 0 0;

                dt,
                dd {
                    width: 50%;
                    margin-bottom: 0;
                    font-size: 0.867rem;
                    color: @text;
                    font-weight: 300;
                    line-height: 2.1;
                    border-bottom: 1px solid @border-color;
                    padding: 5px 0;

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    p {
                        font-size: 0.867rem;
                    }
                }

                dd {
                    text-align: right;
                }
            }
        }

        tfoot {
            th {
                color: @headlines;
                vertical-align: top;
                text-align: right;
                border: none;
                font-weight: 300;
            }

            td {
                text-align: right;
                border-bottom: none;

                span {
                    font-weight: 600;
                    color: @headlines;

                    small {
                        display: none;
                    }
                }

                small {
                    display: block;
                    margin-top: 5px;

                    span {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    form {
        p {
            margin-bottom: 0;

            &.validate-required {
                position: relative;

                &:after {
                    position: absolute;
                    content: "*";
                    color: red;
                    top: 0;
                    right: 5px;
                }
            }

            &.wpgdprc-checkbox {
                font-size: .867rem;
                margin-bottom: 10px;
                padding-right: 20px;

                label {
                    display: block;

                    abbr {
                        display: none;
                    }
                }
            }

            &#billing_company_field,
            &#shipping_company_field {
                display: none;
            }

            &.woocommerce-invalid {
                input.input-text {
                    box-shadow: none;
                    border-color: #ea6e53;
                }
            }

            &.woocommerce-validated {
                input.input-text {
                    box-shadow: none;
                    border-color: #18cd79;
                }

                .select2 {
                    .select2-selection--single {
                        border-color: #18cd79;
                    }
                }
            }

            .woocommerce-input-wrapper {
                display: flex;
            }

            label {
                display: none;
            }

            .woocommerce-form__label-for-checkbox {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                span {
                    font-size: 0.867rem;
                }

                input {
                    margin: -2px 5px 0 0;
                }
            }

            textarea {
                margin-bottom: 0;
                margin-top: 0px;
                min-height: 150px;
                line-height: 1.6;
            }

            .select2 {
                margin-bottom: 10px;
            }
        }
    }

    .woocommerce-shipping-fields {
        .woocommerce-form__label-for-checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            font-size: 0.867rem;

            input {
                margin: -2px 5px 0 0;
            }

            span {
                font-size: 0.867rem;
            }
        }        
    }

    .woocommerce-checkout-review-order {
        display: flex;
        flex-wrap: wrap;

        > h3 {
            padding: 0;
            border: none;
        }

        thead {
            display: none;
        }

        .woocommerce-checkout-review-order-table {
            order: 3;
            background: fade(@border-color, 25%);
        }

        &.active {
            + .place-order {
                display: block !important;
            }
        }
    }

    #shipping_method,
    .payment_methods {
        margin-top: 20px;
        margin-bottom: 0;

        li {
            border: 1px solid @border-color !important;
            margin: 0 0 10px 0;
            position: relative;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                display: none;
            }

            input {
                position: absolute;
                top: 18px;
                left: 20px;
                margin-right: 0;

                &:checked + label {
                    background: fade(@border-color, 25%);
                    color: @link;
                }
            }

            label {
                display: block;
                padding: 8px 20px 8px 50px !important;
                font-size: 0.938rem;

                .woocommerce-Price-amount {
                    font-weight: 400;

                    span {
                        font-weight: 400;
                    }
                }

                img {
                    display: none;
                }
            }

            .payment_box {
                padding: 10px 20px;
            }
        }
    }

    .payment_methods {
        li {
            label {
                &:after {
                    content: "0 Kč";
                    display: inline-block;
                    font-weight: 400;
                }
            }
        }
    }

    .woocommerce-terms-and-conditions-wrapper {
        p {
            margin-bottom: 10px;
            font-size: 0.867rem;
        }
    }

    .place-order {
        .btn {
            margin: 0 auto;
        }
    }

    .woocommerce-form-login-toggle {
        position: absolute;
        right: 0px;
        
        a {
            font-size: 0.867rem;
        }
    }

    .woocommerce-form-login {
        margin-bottom: 25px;

        > p:first-of-type {
            margin-bottom: 20px;
        }

        h3 {
            margin-top: 0;
        }

        .btn {
            width: 100%;
            margin-bottom: 10px;
            height: 50px;
        }

        .woocommerce-form__label-for-checkbox {
            margin-left: 0;
            display: flex !important;
            align-items: center;

            input {
                margin-top: -1px;
            }

            label {
                font-size: 0.867rem;
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        .woocommerce-checkout-review-order {
            .woocommerce-checkout-review-order-table {
                margin: 0 0 20px;
                width: 100%;
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .woocommerce-form-login {
            .form-row-first {
                margin-right: 0;
                width: calc(~"50% - 5px");
            }

            .form-row-last {
                margin-left: 0;
                width: calc(~"50% - 5px");
            }

            .btn {
                width: calc(~"50% - 5px");
            }
        }

        .col2-set {
            width: 100%;
            max-width: 840px;

            .col-1,
            .col-2 {
                margin-bottom: 0;
            }

            .form-row-first {
                width: 100%;
                float: none;
                margin-right: 0;
            }

            .form-row-last {
                width: 100%;
                float: none;
                margin-left: 0;
            }
        }


        #ship-to-different-address .woocommerce-form__input-checkbox {
            position: static;
            transform: none;
            margin-right: 5px;
        }

        #order_review.woocommerce-checkout-review-order {
            width: 100%;
            float: none;
        }

        .place-order {
            max-width: 400px;
            margin-left: auto;
            margin-right: 0;
            margin-bottom: -68px;
        }
    }

    @media screen and (min-width: @screen-desktop) {
        #payment.woocommerce-checkout-payment {
            .woocommerce-checkout--shipping-method h3 {
                margin-top: 0px;
            }
        }

        .place-order {
            .btn {
                width: 100%;
                max-width: none;
            }
        }

        .coupon-wrapper {
            padding-bottom: 15px;          
        }

        .woocommerce-form-login {
            .form-row-first {
                width: 100%;
            }

            .form-row-last {
                width: 100%;
            }
        }
    }
}