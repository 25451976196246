.site-hero {
    height: 240px;
    overflow: hidden;

    > ul {
        li {
            background-image: -moz-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
            background-image: -webkit-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
            background-image: -ms-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
            position: relative;
            overflow: hidden;

            a {
                text-decoration: none;
                display: flex;
                align-items: center;
                min-height: 240px;
                cursor: pointer;

                .container {
                    position: relative;
                    z-index: 1;
                }

                h2 {
                    font-family: "Italianno";
                    text-transform: none;
                    font-size: 3.125rem;
                    color: #fff;
                    line-height: 1;
                    max-width: 180px;
                    margin: 0 0 10px;
                }

                p {
                    font-size: 1.125rem;
                    color: #fff;
                    margin: 0 0 10px;
                    display: none;
                }

                span {
                    color: #fff;
                    font-weight: 300;
                    margin-bottom: 10px;

                    &:after {
                        background: #fff;
                    }
                }

                &:hover {
                    span {
                        color: #fff;

                        &:after {
                            background: #fff;
                        }
                    }
                }

                figure {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        position: absolute;
                        content: "";
                        display: block;
                        width: 60%;
                        height: 100%;
                        background: rgb(0,0,0);
                        background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
                        top: 0;
                        left: 0;
                    }

                    picture {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        max-width: none;
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }
        }
    }
    
    .slick-dots {
        display: flex !important;
        justify-content: center;
        position: absolute;
        align-items: center;
        bottom: 20px;
        width: 100%;
        
        li {
            background: transparent;
            overflow: visible;

            button {
                text-indent: -9999px;
                border: none;
                padding: 0;
                margin: 0;
                background: #fff;
                height: 11px;
                width: 11px;
                display: block;
                border-radius: 100%;
                margin: 0 6px;
                border: 1px solid transparent;
                .trans;
            }

            &.slick-active {
                button {
                    background: transparent;
                    border-color: #fff;
                    transform: scale(1.4);
                }
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        height: 340px;

        > ul {
            li {
                a {
                    min-height: 340px;

                    h2 {
                        max-width: 240px;
                    }

                    figure {
                        &:before {
                            background: linear-gradient(90deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        > ul {
            li {
                a {
                    h2 {
                        font-size: 4rem;
                        margin-bottom: 20px;
                        max-width: 60%
                    }

                    p {
                        display: block;
                        max-width: 45%
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        height: 400px;

        > ul {
            li {
                a {
                    min-height: 400px;

                    h2 {
                        font-size: 4.375rem;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 1.25rem;
                        margin-bottom: 40px;
                        max-width: none;
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-lg-desktop) {
        > ul {
            li {
                a {
                    figure {
                        
                    }
                }
            }
        }
    }
}
