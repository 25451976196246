.btn {
    height: 40px;
    display: block;
    .trans;
    font-size: 1rem;
    border: none;
    line-height: 40px;
    padding: 0;
    width: 110px;
    text-align: center;
    text-decoration: none;
    font-family: 'Merriweather', sans-serif;
    text-transform: uppercase;
    font-weight: 400;

    &.btn-primary {
        background: @main-color;
        color: white;
        padding: 2px;

        &:hover {
            background: darken(@main-color, 15%)
        }
    }

    &.btn-lg {
        width: 100%;
        max-width: 400px;
        line-height: 50px;
        height: 50px;
    }

    &.btn-secondary {
        background: #c18800;
        color: white;

        &:hover {
            background: darken(#c18800, 15%)
        }
    }

    &.btn-inverse {
        background: #fff;
        border: 2px solid @main-color;
        color: @main-color;

        &:hover {
            background: @main-color;
            color: #fff;
        }
    }
}
