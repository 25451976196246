.woocommerce-account {
    .woocommerce {
        .woocommerce-MyAccount-navigation {
            border: 1px solid @border-color;
            margin-bottom: 20px;
            margin-top: -20px;

            > a {
                font-size: 1rem;
                padding: 10px;
                line-height: 1.8;
                position: relative;
                display: block;
                color: @text;
                font-weight: 300;
                .trans;

                &:before,
                &:after {
                    content: "";
                    width: 12px;
                    height: 2px;
                    display: block;
                    position: absolute;
                    background: @text;
                    top: 50%;
                    right: 20px;
                    .trans;
                }

                &:before {
                    transform: rotate(45deg);
                    margin-right: 8px;
                }

                &:after {
                    transform: rotate(-45deg);
                }

                &.active {
                    background: fade(#d7d8da, 25%);
                    color: @link;

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }
                }

                + div {
                    max-height: 0;
                    overflow: hidden;
                    .trans;
                }
            }

            ul {
                margin-bottom: 0;

                li {
                    &:before {
                        display: none;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        padding: 5px 10px;
                        font-size: 0.933rem;
                        color: @text;

                        &:hover {
                            color: @link;
                        }
                    }
                }

                .woocommerce-MyAccount-navigation-link--downloads {
                    display: none;
                }
            }
        }

        .woocommerce-MyAccount-content {
            width: 100%;
            max-width: 840px;
            float: left;

            .order-again {
                a {
                    margin: 20px auto 0;
                }
            }

            .addresses {
                > div {
                    border: 1px solid rgba(178, 178, 178, 0.5);
                    padding: 20px;
                    margin-top: 20px;
                    font-size: 0.933rem;

                    h3 {
                        margin: 0 0 10px;
                    }

                    .btn {
                        margin-top: 20px;
                    }
                }
            }
        }

        .woocommerce-EditAccountForm {
            p {
                width: 100%;
                margin: 0;

                label {
                    display: none;
                }

                > small {
                    margin-bottom: 20px;
                    display: block;
                }

                .btn {
                    margin: 0 auto;
                }
            }

            .h3 {
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(178, 178, 178, 0.5);
                margin-bottom: 20px;
                margin-top: 35px;
            }

            fieldset {
                .h3 + p {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .woocommerce {
            .woocommerce-MyAccount-navigation {
                width: 100%;
                margin-right: 0;
                border: none;
                border-bottom: 1px solid @border-color;
                background: fade(@border-color, 25%);
                margin-bottom: 40px;

                > a {
                    display: none;

                    + div {
                        max-height: none;
                    }
                }

                ul {
                    display: flex;
                    border-top: 0;
                    justify-content: center;

                    li {
                        border-bottom: 0;
                        position: relative;

                        &:after {
                            content: "";
                            display: block;
                            width: 1px;
                            height: 20px;
                            position: absolute;
                            right: 0;
                            background: @border-color;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        a {
                            padding: 10px 40px;
                        }

                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }


            .woocommerce-MyAccount-content {
                float: none;
                margin-left: auto;
                margin-right: auto;

                .order-again {
                    a {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }

                .addresses {
                    display: flex;
                    justify-content: space-between;

                    > div {
                        width: calc(~"50% - 10px");
                        margin-right: 0;
                    }
                }
            }

            .woocommerce-EditAccountForm {
                p {
                    width: 100%;
                    margin: 0;

                    label {
                        display: none;
                    }

                    > span {
                        margin-bottom: 20px;
                        display: block;
                    }

                    .btn {
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }
}

.address-edit-form {
    h3 {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(178, 178, 178, 0.5);
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .btn {
        margin-right: 0;
        margin-left: auto;
    }

    .woocommerce-address-fields__field-wrapper {
        p {
            margin: 0;
            width: 100%;

            label {
                display: none;
            }

            .select2 .select2-selection--single {
                margin-bottom: 10px;
            }
        }
    }
}

.woocommerce-account {
    .woocommerce-error {
        padding: 10px 20px;
        margin: 0;
        border: none;
        margin-bottom: 40px;
        float: left;
        width: 100%;

        li {
            padding: 0;

            &:before {
                display: none;
            }
        }
    }

    .woocommerce-message {
        + p {
            margin-top: 20px;
        }
    }

    .col2-set#customer_login {
        > div {
            width: 100%;

            h2 {
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(178, 178, 178, 0.5);
                margin-bottom: 20px;
                margin-top: 35px;
            }

            p {
                margin: 0;

                label {
                    display: none;
                    margin: 0;

                    &.woocommerce-form__label-for-checkbox {
                        input {
                            position: relative;
                            top: -9px;
                        }
                    }
                }

                .btn {
                    margin: 10px 0;
                    max-width: 260px;
                }
            }
        }

        @media screen and (min-width: @screen-tablet) {
            display: flex;
            justify-content: space-between;

            > div {
                width: calc(~"50% - 10px");
                margin: 0;

                h2 {
                    margin-top: 0;
                }
            }
        }
    }

    .lost_reset_password,
    .reset_password {
        p:not(:first-child) {
            margin: 0;

            label {
                display: none;
            }
        }

        .btn {
            margin: 0 auto;
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .lost_reset_password {
            max-width: 640px;
            margin: 0 auto;

            p {
                .btn {
                    max-width: 260px;
                }
            }

            .woocommerce-form-row {
                display: flex;
                width: 100%;

                input {
                    border-radius: 4px 0 0 4px;
                }

                .btn {
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        .reset_password {
            p:not(:last-of-type) {
                &.form-row {
                    width: calc(~"50% - 5px");
                }
            }

            .btn {
                max-width: 320px;
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}
