body {
    #slb_viewer_wrap {
        .slb_theme_slb_default {
            .slb_group_status {
                color: @text;
                font-style: normal;
                font-size: 1rem;
                font-family: inherit;
                margin-left: 5px;
                margin-top: 5px;
            }

            .slb_data_title {
                font-family: inherit;
                color: @headlines;
                font-size: 1rem;
                margin-top: 5px;

                span {
                    font-weight: 600;
                }
            }
        }
    }
}