.woocommerce-message {
    margin: 20px 0;
    border: none;
    border-radius: 0;
    background: @link;
    font-weight: 300;
}

.woocommerce-error {
    margin: 20px 0;
    border: none;
    border-radius: 0;
    font-weight: 300;  


    li {
        &:before {
            display: none;
        }
    } 
}

#tm-tooltip,
.tm-tip-html {
    background: #fff;
    color: @text;
    text-shadow: none;
    padding: 10px;
    box-shadow: none;
    filter: drop-shadow(0 3px 5px rgba(0,0,0,.3));
    text-align: left;
    max-width: 400px;
    font-size: 0.875rem;
    font-family: 'Merriweather';
    border-radius: 5px;
    left: 0;
    z-index: 99;

    &:after {
        border-top: 10px solid #fff;
        left: 20px !important;
        width: 0;
        height: 0;
        position: absolute;
        content: "";
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        bottom: -10px;
        transform: translateX(-50%);
    }

    &.top {
        transform: translateY(10px);

        &:after {
            border-bottom: 10px solid #fff;
        }
    }

    @media screen and (min-width: @screen-tablet) {
        &:after {
            left: 50% !important;
        }
    }
}

#moove_gdpr_cookie_modal {
    .moove-gdpr-modal-content {
        border-radius: 0;

        .switch {
            .slider {
                &:after {
                    font-weight: 400;
                }
            }
        }

        .moove-gdpr-company-logo-holder {
            img {
                max-height: 77px;
            }
        }

        .moove-gdpr-modal-left-content,
        .moove-gdpr-modal-right-content {
            border-radius: 0;
        }

        .moove-gdpr-modal-left-content {
            .moove-gdpr-branding-cnt {
                a {
                    font-weight: 400;
                    color: #333;
                }
            }

            #moove-gdpr-menu {
                li {
                    button {
                        border-radius: 0;
                        border-color: #f5f5f6;
                        background: #f5f5f6;
                        color: #333;
                    }
                }
            }
        }

        .moove-gdpr-modal-right-content {
            background: #f5f5f6;
        }

        .moove-gdpr-modal-footer-content {
            background: #f5f5f6;

            .moove-gdpr-button-holder {
                button.mgbutton {
                    border-radius: 0;
                    font-weight: 400;
                    font-size: 1rem;
                    height: 40px;
                    width: 120px;
                    padding: 0;
                    line-height: 40px;
                    text-transform: uppercase;

                    &:hover {
                        background: #563d03 !important;
                        color: #fff !important;
                        border-color: #563d03 !important;
                    }
                }
            }
        }

        .moove-gdpr-tab-main {
            h3 {
                &.tab-title {
                    color: #333;
                    font-weight: 400;
                }
            }

            .moove-gdpr-tab-main-content {
                ul,
                ol {
                    li {
                        font-weight: 300;
                        color: #555;
                    }
                }

                p {
                    font-weight: 300;
                    color: #555;
                }
            }
        }
    }
}

#moove_gdpr_cookie_info_bar {
    .moove-gdpr-info-bar-container {
        .moove-gdpr-info-bar-content {
            button {
                &.mgbutton {
                    font-weight: 400;
                    text-transform: uppercase;
                    border-radius: 0;
                }
            }
        }
    }
}
