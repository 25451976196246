.home {
    .site-main {
        .block-cats {
            header {
                h2 {
                    font-family: "Italianno";
                    font-size: 3rem;
                    text-align: center;
                    margin: 30px 0;
                    line-height: 0.8;
                }   
            }

            .cats-wrapper {
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                padding: 15px 0;

                .cat-item {
                    width: 100%;
                    margin: 5px 0;

                    a {
                        border: 1px solid @border-color;
                        display: flex;
                        position: relative;
                        height: 120px;
                        justify-content: space-evenly;
                        align-items: center;
                        text-decoration: none;
                        .trans;

                        figure {
                            display: flex;
                            width: 120px;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;

                            img {
                                max-height: 80%;
                            }
                        }

                        .cat-text {
                            text-align: left;
                            display: flex;
                            align-items: center;
                            position: relative;
                            width: 200px;
                            margin-left: 10px;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                            height: 100px;
                            bottom: 0;

                            h3 {
                                margin: 0;
                                color: #333333;
                                font-size: 1rem;
                                font-weight: 300;
                                line-height: 1.2;
                                width: 100%;
                                .trans;
                            }

                            span {
                                display: block;
                                font-weight: 300;
                                font-size: .875rem;
                                color: #555;
                            }
                        }

                        &:hover {
                            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                            border-color: #fff;

                            .cat-text {
                                h3 {
                                    color: @link;
                                }
                            }
                        }
                    }
                }
            }
        }

        .block-about {
            background: fade(@border-color, 25%);
            margin: 0 -20px;
            padding: 40px 20px;

            header {
                h3 {
                    margin: 0 0 20px;
                    font-size: 1.5rem;
                    text-align: center;
                    line-height: 1;
                }
            }

            p {
                font-size: 0.938rem;
                line-height: 1.8;
            }
        }

        .block-instagram {
            #sb_instagram {
                margin: 0 -20px;
                width: calc(~"100% + 40px") !important;
            }
        }

        .block-map {
            margin: 0 -20px;
            padding: 40px 20px;
            background-image: url("../images/map_bg_w375.jpg");
            background-size: cover;

            .map-address {
                background: #fff;
                padding: 20px;
                width: 220px;

                address {
                    font-weight: 300;
                    font-size: 0.938rem;
                    line-height: 2;

                    strong {
                        font-weight: 400;
                        display: block;
                        font-size: 1rem;
                        color: @headlines;
                    }
                }

                a {
                    font-weight: 300;
                    font-size: 0.938rem;
                }
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        .site-main {
            .block-cats {
                .cats-wrapper {
                    .cat-item {
                        a {
                            height: 140px;
                        }
                    }
                }
            }

            .block-map {
                background-image: url("../images/map_bg_w580.jpg");
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .site-main {
            .block-cats {
                header {
                    h2 {
                        margin-top: 60px;
                        margin-bottom: 40px;
                    }
                }

                .cats-wrapper {
                    margin: 0 -10px;

                    .cat-item {
                        width: calc(~"33.333% - 20px");
                        margin: 10px;

                        a {
                            .cat-text {
                            }
                        }
                    }
                }
            }

            .block-two-column {
                display: flex;
                margin: 0 -20px;

                > * {
                    width: 50%;
                }

                .block-instagram {
                    #sb_instagram {
                        margin: 0;
                        width: 100% !important;
                    }
                }

                .block-map {
                    margin: 0;
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        .site-main {
            .block-cats {
                header {
                    h2 {    
                        margin-bottom: 60px;
                        font-size: 3.5rem;
                    }
                }

                .cats-wrapper {
                    padding: 40px 0;

                    .cat-item {
                        a {
                            display: flex;
                            align-items: center;
                            padding-top: 0;
                            height: 160px;
                            text-decoration: none;
                            justify-content: flex-start;
                            padding-right: 20px;

                            figure {
                                width: 150px;
                                position: relative;
                            }

                            .cat-text {
                                position: relative;
                                width: calc(~"100% - 150px");
                                justify-content: flex-start;
                                flex-wrap: wrap;
                                height: 100px;
                                bottom: 0;

                                h3 {
                                    font-size: 1.125rem;
                                    text-align: left;
                                }

                                .link-underline {
                                    display: block;
                                    font-weight: 300;
                                    font-size: 0.875rem;
                                    color: #555555;

                                    &:after {
                                        background: fade(#555, 50%);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .block-about {
                margin: 0;

                header {
                    h3 {
                        text-align: left;
                    }
                }

                p {
                    font-size: 1rem;
                    line-height: 2.2;
                }
            }

            .block-two-column {
                margin: 80px 0;

                .block-map {
                    padding: 60px;

                    .map-address {
                        padding: 40px;
                        width: 300px;

                        address {
                            line-height: 2.4;

                            strong {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
