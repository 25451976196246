.gallery {
    margin: 40px 0;
    position: relative;
    column-count: 2;
    column-gap: 10px;

    > figure {
         div {
            border: 1px solid @border-color;
            display: flex;
            position: relative;
            padding-top: calc(~"100% - 2px");
            .trans;
            margin-bottom: 10px;
            break-inside: avoid;

            a {
                display: flex;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 5px;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            &:hover {
                border-color: @link;
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        column-count: 4;
    }
}
