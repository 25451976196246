.site-footer {
    padding: 40px 0;
    position: relative;
    
    .footer-top {
        .container {
            &:before {
                display: block;
                width: calc(~"100% - 40px");
                height: 1px;
                background: @border-color;
                position: absolute;
                left: 20px;
                top: 0;
                content: "";
            }
        }
        
        .footer-logo {
            text-align: center;
        }

        .footer-nav {
            margin: 20px 0;

            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                li {
                    margin: 10px;

                    a {
                        font-weight: 300;
                        font-size: 0.938rem;
                    }

                    .sub-menu {
                        display: none;
                    }
                }
            }
        }

        .footer-social {
            padding: 20px 0;
            border-top: 1px solid @border-color;
            border-bottom: 1px solid @border-color;

            iframe {
                width: 100%;   
                max-width: 340px;
                margin: 0 auto;
                display: table;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;

                svg {
                    margin-right: 10px;
                }
            }

            ul {
                display: flex;
                justify-content: center;

                li {
                    margin: 10px;

                    a {
                        opacity: 0.7;
                        display: flex;
                        .trans;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .footer-copyright {
        padding: 20px 0 0;
        text-align: center;

        span {
            font-weight: 300;
            margin-bottom: 20px;
            font-size: 0.938rem;
            display: block;
        }

        ul {
            li {
                a {
                    font-weight: 300;
                    font-size: 0.938rem;
                    color: @text;
                }
            }
        }

        .container {
            > figure {
                display: flex;
                align-items: center;
                justify-content: center;

                figure {
                    margin-left: 20px;
                    display: flex;
                    align-items: center;

                    img {
                        max-height: 36px;
                    }
                }

                a {
                    display: flex;
                    
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        padding-top: 0;

        .footer-top {
            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding: 40px 20px;
                
                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 1px;
                    width: calc(~"100% - 40px");
                    left: 20px;
                    background: @border-color;
                }

                &:before {
                    top: 0;
                }

                &:after {
                    bottom: 0;
                }

                .footer-logo {
                    display: none;
                }

                .footer-nav {
                    margin: 0;
                    margin-right: auto;

                    ul {
                        li {
                            margin: 0 10px;
                        }
                    }
                }

                .footer-social {
                    border: none;
                    padding: 0;
                    width: 340px;
                }
            }
        }

        .footer-copyright {
            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    margin-bottom: 0;
                    font-size: 0.813rem;
                }

                ul {
                    li {
                        a {
                            font-size: 0.813rem;
                        }
                    }
                }

                &:after,
                &:before {
                    display: none;
                }
            }
        }
    }

    @media screen and (min-width: @screen-lg-desktop) {
        .footer-top {
            .container {
                .footer-logo {
                    display: block;
                }

                .footer-nav {
                    padding-left: 40px;
                }
            }
        }
    }
}
