.page {
    .site-main {
        padding-bottom: 40px;

        article {
            > header {
                background-image: -moz-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
                background-image: -webkit-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
                background-image: -ms-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
                padding: 40px 0;

                h1 {
                    text-align: left;
                    margin: 0 0 10px;
                    font-family: "Italianno";
                    text-transform: none;
                    font-size: 3.5rem;
                    color: #111;
                    line-height: 1;
                }

                + section {
                    padding-top: 40px;

                    > h2 {
                        margin-top: 40px;
                        margin-bottom: 20px;
                    }

                    > h3 {
                        margin-bottom: 10px;
                        margin-top: 20px;
                    }

                    .aligncenter {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .border-top {
        border-top: 1px solid @border-color;
        margin-top: 40px;

        form {
            max-width: 640px;
        }
    }

    .faq-wrapper {
        margin-top: 20px;

        article {
            border: 1px solid @border-color;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                padding: 15px 20px;
                display: block;
                cursor: pointer;
                position: relative;

                &.active {
                    background: fade(#d7d8da, 25%);

                    h3 {
                        color: @link;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }

                h3 {
                    font-size: 1rem;
                    margin: 0 20px 0 0;
                    line-height: 1.8;
                    .trans;

                    &:before,
                    &:after {
                        content: "";
                        width: 12px;
                        height: 2px;
                        display: block;
                        position: absolute;
                        background: @text;
                        top: 50%;
                        right: 20px;
                        .trans;
                    }

                    &:before {
                        transform: rotate(45deg);
                        margin-right: 8px;
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }

                + div {
                    max-height: 0;
                    overflow: hidden;
                    .trans;

                    p {
                        margin: 20px;
                        font-size: 0.933rem;
                        line-height: 1.9;
                    }
                }
            }
        }
    }

    .page-order {
        margin-top: 30px;

        .btn {
            margin: 20px auto 0;
        }
    }

    .page-gallery {
        margin-top: 40px;
        position: relative;

        &.showcase {
            padding-bottom: 35px;            
        }

        > figure {
            column-count: 2;
            column-gap: 10px;

            .link-center {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0px;
                white-space: nowrap;
                font-weight: 300;
            }

            .link-image {
                border: 1px solid @border-color;
                display: flex;
                position: relative;
                padding-top: calc(~"100% - 2px");
                .trans;
                margin-bottom: 10px;
                break-inside: avoid;

                figure {
                    display: flex;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    //padding: 5px;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img {
                        //max-height: 80%;
                    }
                }

                &:hover {
                    border-color: @link;
                }
            }
        }
    }

    .page-portfolio {
        .portfolio-item {
            margin-bottom: 40px;
            padding-bottom: 20px;
            border-bottom: 1px solid @border-color;
        }
    }

    .about-basic {
        padding-bottom: 40px;
    }

    .about-person {
        figure {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
                max-width: 307px;
            }

            figcaption {
                display: block;
                
                p {
                    font-size: 0.867rem;
                }

                h2 {
                    margin: 5px 0;
                    font-size: 1.25rem;
                }
            }
        }
    }

    .about-map {
        .h3 {
            margin-bottom: 20px;
        }
    }

    .carat-table {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;

        figure {
            width: percentage(1/4);
            text-align: center;
            height: 120px;
            padding-bottom: 40px;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            border-bottom: 1px solid @border-color;

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
                border-bottom: 0;
                margin-bottom: 0;
            }

            figcaption {
                font-weight: 300;
                font-size: 0.800rem;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 10px
            }
        }
    }

    .cut-table {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;

        figure {
            width: percentage(1/2);
            text-align: center;
            height: 200px;
            padding-bottom: 40px;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            border-bottom: 1px solid @border-color;

            &:nth-child(11),
            &:nth-child(12) {
                border-bottom: 0;
                margin-bottom: 0;
            }

            figcaption {
                font-weight: 300;
                font-size: 0.800rem;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 10px
            }
        }        
    }

    .type-table {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;

        figure {
            width: percentage(1/2);
            text-align: center;
            height: 160px;
            padding-bottom: 40px;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            border-bottom: 1px solid @border-color;

            &:nth-child(25) {
                border-bottom: 0;
                margin-bottom: 0;
            }

            figcaption {
                font-weight: 300;
                font-size: 0.800rem;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 10px
            }
        }        
    }

    @media screen and (min-width: @screen-phone) {
        .type-table {
            figure {
                width: percentage(1/3);
            }        
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .site-main {
            article {
                > header {
                    h1 {
                        font-size: 4rem;
                    }
                }
            }
        }

        .page-gallery {
            > figure {
                column-count: 3;
            }
        }

        .page-portfolio {
            .portfolio-item {
                display: flex;
                justify-content: space-between;

                .portfolio-text {
                    width: calc(~"50% - 10px");
                    order: 2;
                }
            
                figure {
                    width: calc(~"50% - 10px");
                    max-width: 400px;
                    order: 1;
                }
            }
        }

        .about-basic {
            padding-bottom: 60px;
        }

        .cut-table {
            figure {
                width: percentage(1/3);

                &:nth-child(10) {
                    margin-bottom: 0;
                    border-bottom: 0;
                }
            }
        }

        .type-table {
            figure {
                width: percentage(1/4);
            }        
        }

        .about-map {
            iframe {
                height: 350px;
            }
        }

        .carat-table {
            figure {
                height: 140px;
                padding-bottom: 60px;

                figcaption {
                    bottom: 20px;
                }
            }
        }
    }

    @media screen and (min-width: @screen-desktop) {
        .site-main {
            padding-bottom: 80px;

            article {
                > header {
                    h1 {
                        font-size: 4.375rem;
                    }
                }
            }
        }

        .page-order {
            margin-top: 70px;

            .btn {
                margin: 40px auto 0;
            }
        }

        .page-gallery {
            > figure {
                column-gap: 20px;

                .link-image {
                    margin-bottom: 20px;
                }
            }
        }

        .carat-table {
            margin-top: 30px;

            figure {
                width: percentage(1/8);
                border-bottom: 0;
                margin-bottom: 0;

                figcaption {
                    bottom: 10px;
                    font-size: 0.933rem;
                }
            }
        }

        .type-table {
            figure {
                width: percentage(1/5);
                height: 140px;
                padding-bottom: 60px;

                figcaption {
                    bottom: 20px;
                    font-size: 0.933rem;
                }

                &:nth-child(24),
                &:nth-child(23),
                &:nth-child(22),
                &:nth-child(21) {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            } 
        }

        .cut-table {
            figure {
                width: percentage(1/4);

                figcaption {
                    font-size: 0.933rem;
                }

                &:nth-child(9) {
                    margin-bottom: 0;
                    border-bottom: 0;
                }
            }
        }

        .page-portfolio {
            .portfolio-item {
                .portfolio-text {
                    width: calc(~"100% - 480px");
                }
            
                figure {
                    width: 400px;
                }
            }
        }

        .faq-wrapper {
            margin-top: 40px;

            article {
                margin-bottom: 20px;
            }
        }

        .about-basic {
            padding-bottom: 0;
        }

        .about-person {
            figure {   
                display: block;
                float: left;
                margin: 0 40px 20px 0;
                width: auto;
            }
        }

        .about-map {
            iframe {
                height: 450px;
            }
        }
    }
}