.single-product {
    .site-main {
        padding-bottom: 40px;
        overflow: hidden;

        article {
            > header {
                background-image: -moz-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
                background-image: -webkit-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
                background-image: -ms-linear-gradient( 45deg, rgb(242,243,245) 0%, rgb(238,239,240) 100%);
                padding: 40px 0;

                h1 {
                    text-align: left;
                    margin: 0 0 10px;
                    font-family: "Italianno";
                    text-transform: none;
                    font-size: 3.5rem;
                    color: #111;
                    line-height: 1;
                }

                + section {
                    padding-top: 40px;
                }
            }

            .wpc-product-form {
                display: none !important;
            }

            .woocommerce-product-gallery {
                margin: 20px auto;
                position: relative;
                max-width: 420px;
                width: 100%;
                padding-top: 100%;

                .flex-viewport {
                    margin-top: -100%;
                }

                .woocommerce-product-gallery__wrapper {
                    position: absolute;
                    top: 0px;
                    display: flex;
                    align-items: center;
                }

                .woocommerce-product-gallery__trigger {
                    background: url(../../assets/images/icon_search.svg) no-repeat;
                    top: 0px;
                    right: 0px;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    z-index: 9;
                    background-size: cover;

                    img {
                        display: none !important;
                    }
                }

                ol {
                    list-style: none;
                    display: flex;
                    margin-top: 10px;

                    li {
                        width: calc(~"33.333% - 5px");
                        max-width: 102px;
                        display: flex;
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            border: 1px solid @border-color;
                            height: 100%;
                            width: 100%;

                            &.flex-active {
                                border-color: @link;
                            }
                        }
                    }
                }
            }

            .product-configurator {
                .price {
                    padding: 0;
                    text-align: right;

                    &.simple {
                        max-width: 400px;
                        margin: 0 auto;

                        > .woocommerce-Price-amount,
                        ins {
                            &:before {
                                display: none;
                            }
                        } 
                    }

                    del {
                        opacity: 1;

                        span {
                            color: @text;
                            font-size: 1.375rem;
                            font-weight: 400;
                        }
                    }

                    .price-extax {
                        margin-top: 5px;
                        display: block;
                    }

                    > .woocommerce-Price-amount,
                    ins {
                        color: @link;
                        text-decoration: none;
                        font-size: 1.688rem;
                        font-weight: 700;
                        display: block;
                        margin-left: 0;
                        line-height: 1;

                        &:before {
                            content: "od ";
                        }

                        span {
                            font-weight: 700;
                            font-size: 1.688rem;
                        }
                    }
                }

                .product-final-price {
                    padding: 20px;
                    border-top: 1px solid rgba(215, 216, 218, 0.5);
                    background: #f5f5f6;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 -20px;
                    position: relative;

                    .price-text {
                        font-weight: 300;
                        font-size: 0.875rem;
                        display: flex;
                        align-items: center;
                        width: auto;
                        cursor: auto;

                        &.icon-info {
                            &:after {
                                content: "i";
                                display: flex;
                                justify-content: center;
                                line-height: 16px;
                                border-radius: 100%;
                                color: #fff;
                                font-weight: 600;
                                margin-left: 8px;
                                background: #a07105;
                                height: 14px;
                                width: 14px;
                                font-size: 0.667rem;
                            }
                        }

                        + .tm-tip-html {
                            position: absolute;
                            opacity: 0;
                            .trans;
                            pointer-events: none;
                            bottom: 60px;
                            //transform: translateX(-110px);
                            max-width: 340px;
                            left: 37px;
                        }

                        &:hover {
                            + .tm-tip-html {
                                opacity: 1;
                                pointer-events: auto;
                            }
                        }
                    }

                    .price {
                        color: @link;
                        font-size: 1.688rem;
                        font-weight: 700;
                    }

                    span {
                        &:before {
                            display: none;
                        }
                    }
                }

                .single_variation_wrap {
                    position: relative;

                    .woocommerce-variation-price .price {
                        position: absolute;
                        right: 20px;
                        top: 140px;
                        z-index: 1;

                        span {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    &.cat-snubni {
                        .woocommerce-variation-price {
                            display: none;
                        }
                    }
                }

                .reset_variations {
                    display: none !important;
                }

                .tmcp-select {
                    &.disabled {
                        + .select2 {
                            pointer-events: none;

                            .select2-selection__arrow {
                                display: none;
                            }
                        }
                    }
                }

                .tm-extra-product-options {
                    background: #f5f5f6;

                    &.tm-epo-variation-section {
                        padding: 20px 20px 0;
                        margin: 0 -20px;
                        width: calc(~"100% + 40px");
                        max-width: none;
                    }

                    .tm-cell {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .tmcp-ul-wrap {
                            list-style: none;

                            li {
                                padding: 0;

                                &:before {
                                    display: none;
                                }
                            }
                        }

                        &.cpf-type-radio {
                            margin-top: 10px;

                            .tmcp-field-wrap {
                                display: flex;
                                justify-content: flex-end;

                                input {
                                    display: none;
                                }

                                label {
                                    width: auto;
                                    padding-right: 20px;
                                    max-width: none;

                                    .tm-label {
                                        font-weight: 300;
                                    }
                                }
                            }
                        }

                        .tm-variation-ul-color {
                            display: flex;
                            justify-content: space-evenly;
                            flex-wrap: wrap;

                            &:after {
                                content: "Vyberte barvu snubních prstenů";
                                display: block;
                                text-align: center;
                                font-size: 0.875rem;
                                font-weight: 300;
                                width: 100%;
                            }

                            &.hide {
                                &:after {
                                    display: none;
                                }
                            }

                            li {
                                width: auto;

                                .radio_image {
                                    width: 30px !important;
                                    height: 30px !important;
                                    min-height: auto !important;
                                    min-width: auto !important;
                                    border: none;

                                    + .tc-label {
                                        background: #fff;
                                        color: @text;
                                        text-shadow: none;
                                        padding: 5px 8px;
                                        box-shadow: none;
                                        filter: drop-shadow(0 3px 5px rgba(0,0,0,.3));
                                        text-align: left;
                                        max-width: 400px;
                                        font-family: 'Merriweather';
                                        border-radius: 5px;
                                        left: 50%;
                                        transform: translate(-50%,-100%);
                                        position: absolute;
                                        top: -10px;
                                        z-index: 99;
                                        white-space: nowrap;
                                        font-size: 0;

                                        &:after {
                                            content: "";
                                            font-size: 0.875rem;
                                            margin-left: 5px;
                                            display: inline-block;
                                        }

                                        &.hidden {
                                            display: none !important;
                                        }

                                        &:before {
                                            border-top: 6px solid #fff;
                                            left: 50%;
                                            width: 0;
                                            height: 0;
                                            position: absolute;
                                            content: "";
                                            border-right: 6px solid transparent;
                                            border-left: 6px solid transparent;
                                            bottom: -6px;
                                            transform: translateX(-50%);
                                        }
                                    }
                                }

                                input {
                                    &[value="cervena"] {
                                        ~ .tc-label {
                                            &:after {
                                                content: "Červené zlato";
                                            }
                                        }
                                    }

                                    &[value="bila"] {
                                        ~ .tc-label {
                                            &:after {
                                                content: "Bílé zlato";
                                            }
                                        }
                                    }

                                    &[value="zluta"] {
                                        ~ .tc-label {
                                            &:after {
                                                content: "Žluté zlato";
                                            }
                                        }
                                    }
                                }

                                label:hover {
                                    .radio_image + .tc-label {
                                        display: block !important;

                                        &.hidden {
                                            display: none !important;
                                        }
                                    }
                                }

                                &.tm-attribute-disabled {
                                    span {
                                        position: relative;

                                        &:before,
                                        &:after {
                                            content: "";
                                            display: block;
                                            width: 40px;
                                            left: -6px;
                                            top: 14px;
                                            height: 1px;
                                            position: absolute;
                                            background: black;
                                        }

                                        &:before {
                                            transform: rotate(45deg);
                                        }

                                        &:after {
                                            transform: rotate(-45deg);
                                        }
                                    }
                                }

                                input {
                                    &:checked {
                                        + span {
                                            box-shadow: none;
                                            border: 1px solid fade(#000, 50%);

                                            &:after {
                                                content: "";
                                                display: block;
                                                position: absolute;
                                                width: 8px;
                                                height: 8px;
                                                background: #111111;
                                                border-radius: 100%;
                                                top: 15px;
                                                left: 50%;
                                                transform: translate(-50%,-50%);
                                            }
                                        }
                                    }
                                }


                            }
                        }

                        .tm-epo-field-label {
                            padding: 0;
                            width: auto;
                            max-width: 50%;
                            font-weight: 300;
                            font-size: 0.875rem;
                            cursor: auto;

                            &.tm-tooltip {
                                display: flex;
                                align-items: center;

                                &:after {
                                    content: "i";
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 100%;
                                    color: #fff;
                                    font-weight: 600;
                                    margin-left: 8px;
                                    background: @link;
                                    height: 14px;
                                    width: 14px;
                                    font-size: 0.667rem;
                                }
                            }

                            .tm-epo-required {
                                display: none;
                            }
                        }

                        .tm-extra-product-options-container {
                            width: 50%;
                        }
                    }
                }

                .variations_form {
                    margin-top: 20px;

                    .variations {
                        margin: 0 -20px;
                        width: calc(~"100% + 40px");
                        background: #f5f5f6;
                        padding: 20px 20px 0;

                        td {
                            border-bottom: 0;
                            padding: 0 0 20px;
                            width: 50%;
                            vertical-align: middle;  

                            .select2 {
                                margin-bottom: 0;
                            }

                            label {
                                font-weight: 300;
                                font-size: 0.875rem;
                            }
                        }
                    }

                    .tc-extra-product-options,
                    .tc-totals-form {
                        margin: 0 -20px;
                        width: calc(~"100% + 40px");
                        background: #f5f5f6;
                        max-width: none;

                        .tm-cell {
                            margin-bottom: 10px;
                            // display: flex;
                            // align-items: center;

                            &.tc-hidden {
                                + .font-select {
                                    display: none;
                                }
                            }
                        }

                        ul {
                            list-style: none;

                            &.tm-element-ul-checkbox {
                                li {
                                    display: flex;
                                    justify-content: flex-end;

                                    label {
                                        width: auto;
                                        padding-right: 20px;
                                        
                                        input {
                                            top: -1px;
                                        }

                                        span {
                                            font-weight: 300 !important;
                                        }
                                    }
                                }
                            }

                            li {
                                padding: 0 20px;

                                .cpf-type-checkbox {
                                    margin-bottom: 5px;
                                }

                                .cpf-section {
                                    margin: 0;
                                    flex-wrap: wrap;
                                }

                                label {
                                    padding: 0;
                                    width: 50%;
                                    max-width: 50%;
                                    font-weight: 300;
                                    font-size: 0.875rem;

                                    .tm-epo-required {
                                        display: none;
                                    }

                                    &.tm-tooltip {
                                        display: flex;
                                        align-items: center;

                                        &:after {
                                            content: "i";
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 100%;
                                            color: #fff;
                                            font-weight: 600;
                                            margin-left: 8px;
                                            background: @link;
                                            height: 14px;
                                            width: 14px;
                                            font-size: 0.667rem;
                                        }
                                    }
                                }

                                .tm-extra-product-options-container {
                                    width: 50% !important;
                                    max-width: 50%;
                                }

                                &.tmcp-field-wrap {
                                    padding: 0;

                                    input {
                                        font-size: 1.25rem;
                                    }
                                }

                                &:before {
                                    display: none;
                                }

                                .cpf-type-textfield {
                                    .tm-extra-product-options-container {
                                        width: 100% !important;
                                        max-width: 100%;

                                        label {
                                            width: 100%;
                                            max-width: 100%;
                                        }

                                        input {
                                            margin-bottom: 0;
                                        }
                                    }
                                }

                                .cpf-type-select {
                                    display: flex;
                                    align-items: center;

                                    > div {
                                        ul {
                                            li {
                                                label {
                                                    width: 100%;
                                                    max-width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .tc-epo-totals {
                        margin: 0 20px;
                        border-top: 1px solid @border-color;

                        dl {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            dt {
                                font-weight: 300;
                                font-size: 0.875rem;
                            }

                            dd {
                                span {
                                    color: @link;
                                    font-size: 1.688rem;
                                }
                            }
                        }
                    }

                    .btn-primary {
                        margin: 20px auto 0;
                    }

                    .product-button {
                        margin-top: 10px;
                        padding-top: 10px;
                        border-top: 1px solid @border-color;
                    }
                }
            }

            .woocommerce-tabs {
                padding: 0 0 40px;

                .product-desc {
                    margin-bottom: 40px;
                }

                .shop_attributes {
                    margin: 0;
                    max-width: 640px;

                    tr {
                        th {
                            vertical-align: middle;
                            text-align: left;
                            font-weight: 400;
                        }
                        
                        td {
                            font-weight: 300;
                        }

                        &:last-child {
                            td {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }

            &.product_cat-kombinovane-prsteny {
                .product-configurator {
                    .tm-extra-product-options {
                        .tm-cell {
                            .tm-variation-ul-color {
                                li {
                                    input {
                                        &[value="cervena"] {
                                            + span {
                                                background: rgb(192,192,192); /* Old browsers */
                                                background: -moz-linear-gradient(-45deg, rgba(192,192,192,1) 49%, rgba(224,156,117,1) 51%); /* FF3.6-15 */
                                                background: -webkit-linear-gradient(-45deg, rgba(192,192,192,1) 49%,rgba(224,156,117,1) 51%); /* Chrome10-25,Safari5.1-6 */
                                                background: linear-gradient(135deg, rgba(192,192,192,1) 49%,rgba(224,156,117,1) 51%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0c0c0', endColorstr='#e09c75',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                                            }

                                            ~ .tc-label {
                                                &:after {
                                                    content: "Kombinace bílé/červené zlato";
                                                }
                                            }
                                        }

                                        &[value="zluta"] {
                                            + span {
                                                background: rgb(192,192,192); /* Old browsers */
                                                background: -moz-linear-gradient(-45deg, rgba(192,192,192,1) 49%, rgba(196,164,71,1) 51%); /* FF3.6-15 */
                                                background: -webkit-linear-gradient(-45deg, rgba(192,192,192,1) 49%,rgba(196,164,71,1) 51%); /* Chrome10-25,Safari5.1-6 */
                                                background: linear-gradient(135deg, rgba(192,192,192,1) 49%,rgba(196,164,71,1) 51%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0c0c0', endColorstr='#c4a447',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                                            }

                                             ~ .tc-label {
                                                &:after {
                                                    content: "Kombinace bílé/žluté zlato";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.product_cat-zasnubni-prsteny {
                .tmcp-field-wrap .price {
                    display: none !important;
                }
            }

            .related {
                border-top: 1px solid @border-color;

                .h2 {
                    margin-top: 20px;
                }

                ul {
                    position: relative;

                    .slick-list {
                        overflow: visible;
                    }

                    .slick-arrow {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        border: none;
                        padding: 0;
                        width: 40px;
                        height: 80px;
                        background: transparent;
                        z-index: 99;

                        &.slick-prev {
                            left: -10px;
                        }

                        &.slick-next {
                            right: -10px;

                            &:before {
                                transform: rotate(-45deg);
                                right: auto;
                                left: 10px;
                            }

                            &:after {
                                transform: rotate(45deg);
                                right: auto;
                                left: 10px;
                            }
                        }

                        &:before,
                        &:after {
                            content: "";
                            width: 30px;
                            height: 2px;
                            display: block;
                            position: absolute;
                            background: @text;
                            top: 50%;
                            right: 10px;
                            .trans;
                        }

                        &:before {
                            transform: rotate(45deg);
                            margin-top: 10px;
                        }

                        &:after {
                            transform: rotate(-45deg);
                            margin-top: -10px;
                        }
                    }

                    li {
                        margin-bottom: 0;

                        &:before {
                            display: none;
                        }

                        a {
                            text-decoration: none;

                            img {
                                margin: 0 auto;
                            }

                            h2 {
                                margin: 0px;
                                font-size: 1rem;
                                .trans;
                            }

                            .price {
                                font-size: 1rem;
                                margin-bottom: 0;
                                color: @link;

                                span {
                                    font-weight: 400;
                                }

                                del {
                                    color: @text;
                                    opacity: 1;
                                    font-size: 0.875rem;
                                }

                                ins {
                                    text-decoration: none;
                                }
                            }

                            &:hover {
                                h2 {
                                    color: @link;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-phone) {
        .site-main {
            article {
                .woocommerce-product-gallery {
                    padding-top: 380px;

                    .flex-viewport {
                        margin-top: -380px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: @screen-tablet) {
        .site-main {
            article {
                .woocommerce-product-gallery {
                    max-width: 320px;
                    float: left;
                }

                .product-configurator {
                    width: calc(~"100% - 360px");
                    float: right;
                    padding-top: 40px;

                    .variations_form {
                        .variations,
                        .tc-extra-product-options,
                        .tc-totals-form {
                            margin: 0;
                            width: 100%;
                        }

                        .tm-extra-product-options.tm-epo-variation-section {
                            width: 100%;
                            margin: 0;
                        }

                        .product-final-price {
                            margin: 0;
                            width: 100%;

                            .price-text {
                                + .tm-tip-html {
                                    transform: translateX(-110px);
                                    left: 0;
                                }
                            }
                        }
                    }
                }

                .woocommerce-tabs {
                    clear: both;

                    .h2 {
                        margin-top: 20px;
                    }
                }
            }
        }
    }


    @media screen and (min-width: @screen-desktop) {
        .site-main {
            article {
                .woocommerce-product-gallery {
                    margin-top: 40px;
                    max-width: none;
                    width: calc(~"50% - 40px");
                    padding-top: 420px;
                    overflow: hidden;

                    .flex-viewport {
                        margin-top: -420px;
                    }

                    ol {
                        li {
                            max-width: 152px;
                        }
                    }
                }

                .product-configurator {
                    width: calc(~"50% - 40px");
                    float: right;
                    padding-top: 40px;

                    .variations_form {
                        .variations,
                        .tc-extra-product-options,
                        .tc-totals-form {
                            margin: 0;
                            width: 100%;
                        }
                    }
                }

                .woocommerce-tabs {
                    clear: none;
                    width: calc(~"50% - 40px");
                    float: left;
                    position: relative;
                    z-index: 1;
                }

                .related {
                    clear: both;

                    .slick-slider {
                        overflow: visible;
                    }
                    
                    ul {
                        li {
                            a {
                                overflow: hidden;
                                padding: 0 0 10px;

                                &:hover {
                                    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
                                    z-index: 9;
                                    position: relative;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-share,
.product-button {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;

        li {
            display: flex;
            align-items: center;
            position: relative;
            padding-right: 10px;
            margin-right: 10px;

            &:before {
                width: 1px;
                height: 20px;
                position: absolute;
                right: -10px;
                background: @border-color;
                top: 50%;
                transform: translateY(-50%);
            }

            &:last-child {
                padding-right: 0;
                margin-right: 0;

                &:before {
                    display: none;
                }
            }

            a {
                color: @headlines;
                font-size: 0.813rem;
                font-weight: 300;
                display: flex;
                align-items: center;

                span {
                    font-weight: 300;
                    margin-left: 10px;
                }
            }
        }
    }
}

.btn-buy {
    margin: 40px auto 20px;
}

.product-certificate {
    font-size: 0.813rem;
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 300;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 40px;
}

.measuring-rings {
    width: 100%;
    max-width: 400px;
    margin: 40px auto 0;
}

@media screen and (min-width: @screen-desktop) {
    .product-certificate {
        font-size: 0.875rem;
        margin-top: 20px;
    }

    .product-share,
    .product-button {
        ul {
            li {
                a {
                    font-size: 0.875rem;
                }

                &:first-child {
                    margin-right: 20px;
                    padding-right: 20px;

                    &:after {
                        right: 20px;
                    }
                }
            }
        }
    }
}
